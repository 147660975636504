import React, { useState, useEffect } from "react"
import Img from "gatsby-image"
import { motion } from "framer-motion"
import { useSwipeable } from "react-swipeable"
import constants from "../../constants"

const ImageSlider = ({
  images,
  heading,
  tinyHeading,
  paragraph,
  arrowBgColor,
  arrowColor,
  bgColor,
}) => {
  const [position, positionSet] = useState(1)
  const [width, setWidth] = useState(null)

  const handleWidth = () => {
    const imageWidth = document.querySelector("#slideImage").clientWidth
    return setWidth(
      imageWidth / 16 < 24 ? imageWidth / 16 + 1 : imageWidth / 16 + 3
    )
  }

  useEffect(() => {
    window.addEventListener("resize", handleWidth)
    return () => {
      window.removeEventListener("resize", handleWidth)
    }
  }, [])

  const handleClick = dir => {
    if (dir === "Right") {
      if (position < images.length - 2) {
        positionSet(position + 1)
      }
    }
    if (dir === "Left") {
      if (position > 1) {
        positionSet(position - 1)
      }
    }
  }

  const handlers = useSwipeable({
    onSwiped: ({ dir }) => {
      if (dir === "Left") {
        if (position < images.length - 1) {
          positionSet(position + 1)
        }
      }
      if (dir === "Right") {
        if (position > 0) {
          positionSet(position - 1)
        }
      }
    },
  })

  // text-center pt-8 lg:pt-12 mb-16 md:max-w-screen-s lg:mb-10

  return (
    <div className="text-center pt-10 lg:pt-20 pb-16 md:max-w-screen-s lg:pb-10">
      <div
        className={`px-6 pb-6 lg:pb-8 mx-auto ${
          !tinyHeading ? "lg:max-w-screen-lg" : "lg:max-w-screen-sm"
        } `}
      >
        <h2
          className={`${constants.h2}`}
          dangerouslySetInnerHTML={{ __html: heading }}
        />

        <span className="text-sm block mt-4 md:text-base lg:text-xl">
          {" "}
          {paragraph}{" "}
        </span>
      </div>

      <div
        className={`relative h-96 w-full overflow-hidden flex items-center justify-center md:h-120 xl:h-160 !bg-[${bgColor}]`}
      >
        <div
          className="h-full w-full flex items-center justify-center"
          {...handlers}
        >
          <div className="relative h-full w-60 md:w-96 xl:w-120">
            {images.map((image, index) => (
              <motion.div
                id="slideImage"
                onLoad={handleWidth}
                className="absolute overflow-hidden h-full w-full "
                key={index}
                initial={{ scale: 1, rotation: 0 }}
                animate={{
                  left: `${(index - position) * width}rem`,
                }}
                transition={{
                  duration: 0.5,
                  ease: [0.85, 0, 0.15, 1],
                }}
              >
                <Img
                  objectFit="cover"
                  alt=""
                  style={{
                    height: "100%",
                    width: "100%",
                  }}
                  fluid={image.childImageSharp.fluid}
                />
              </motion.div>
            ))}
          </div>
        </div>

        <svg
          className="hidden lg:block cursor-pointer rotate-180 w-20 absolute top-1/2 left-5 transform -translate-y-1/2"
          onClick={e => handleClick("Left")}
          width="100"
          height="100"
          viewBox="0 0 100 100"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="50"
            cy="50"
            r="50"
            className={`fill-current text-[${arrowBgColor}]`}
          />
          <path
            opacity="0.8"
            d="M35 50L65 50M65 50L50 35M65 50L50 65"
            stroke={arrowColor}
          />
        </svg>
        <svg
          className="hidden lg:block cursor-pointer w-20 absolute top-1/2 right-5 transform -translate-y-1/2"
          onClick={e => handleClick("Right")}
          width="100"
          height="100"
          viewBox="0 0 100 100"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="50"
            cy="50"
            r="50"
            className={`fill-current text-[${arrowBgColor}]`}
          />
          <path
            opacity="0.8"
            d="M35 50L65 50M65 50L50 35M65 50L50 65"
            stroke={arrowColor}
          />
        </svg>
      </div>
    </div>
  )
}

export default ImageSlider
