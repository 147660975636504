import React from "react"
import Layout from "../components/layout"
import SEO from "components/seo"
import { useStaticQuery, graphql } from "gatsby"
import ImageSlider from "../components/about-imagesSlider/about-imageSlider"
import constants from "../constants/index"
import { StaticImage } from "gatsby-plugin-image"
import Marquee from "react-fast-marquee"
import InlineVideo from "../components/inline-video"
import { ss23_videoBasepath } from "./LandingPage/ss23Landing"

const AboutPage = ({ location }) => {
  const data = useStaticQuery(graphql`
    fragment fluidImage2 on File { childImageSharp { fluid(maxWidth: 1920, quality:80, ) { ...GatsbyImageSharpFluid_withWebp_noBase64 } } }
    query {
      firstSlides: allFile(
        filter: {
          extension: { regex: "/(jpg)|(jpeg)|(png)/" }
          relativeDirectory: { eq: "images/about/firstSlides" }
        }
      ) {
        nodes {
          ...fluidImage2
        }
      }
    }
  `)
  const firstSlides = data.firstSlides.nodes
  const overallPadding = constants.landingOverallPadding
  const Grid = ({ children, className }) => (
    <div className={`grid grid-cols-12 ${className}`}>{children}</div>
  )
  return (
    <Layout location={location}>
      <SEO title={`Catalogue`} pathname={location.pathname} />
      <div className={`!bg-white`}>
        <div className="relative h-70vh lg:h-90vh flex justify-center flex-col items-center text-white text-center px-6 md:px-32 lg:px-40 overflow-hidden">
          <div className="absolute left-0 top-0 w-full h-full">
            <StaticImage
              src={`../../static/images/about/hero_image.jpg`}
              alt={``}
              className="w-full h-full"
            />
          </div>
          <h1 className="relative z-10 text-[38px] leading-none mb-3 md:text-5xl lg:text-9xl">
            For now, for <br /> tomorrow, for life
          </h1>
          <p className="z-10 text-sm md:text-xl lg:text-3xl">
            Functional and sustainable leather goods
          </p>
        </div>

        <div className={`w-full bg-black`}>
          <Marquee gradient={false} speed={50}>
            <div
              className={`bg-black text-white ${constants.h2} leading-none lg:leading-tight flex items-center pt-3 lg:pt-2 pb-4 lg:pb-4 whitespace-nowrap`}
            >
              <div>&nbsp;SS23 Tools To Live Freely ⋅</div>
              <div>&nbsp;SS23 Tools To Live Freely ⋅</div>
              <div>&nbsp;SS23 Tools To Live Freely ⋅</div>
              <div>&nbsp;SS23 Tools To Live Freely ⋅</div>
              <div>&nbsp;SS23 Tools To Live Freely ⋅</div>
              <div>&nbsp;SS23 Tools To Live Freely ⋅</div>
            </div>
          </Marquee>
        </div>

        <div className={`mx-auto w-full lg:w-8/12 px-6 lg:px-10 text-center py-10 lg:py-20 flex flex-col justify-center items-center`}>
          <p className={`${constants.h2} lg:mb-4`}>
            Atelier Of The Future
          </p><br/>
          <p className={`${constants.paragraph} w-full lg:w-9/12`}>
            A walk through our Atelier Of The Future as we show the sheer amount of leather, art, innovation and craft that goes into each and every bag. Made in-house; we hold everything needed to make the carry-alls people use in their lives. 
          </p>
        </div>

        <Grid className={`${overallPadding} mb-0 lg:mb-32 h-full`}>
          <InlineVideo
            src="AtelierOfTheFuture.mp4"
            basepath={ss23_videoBasepath}
            className={`col-start-1 col-end-13 lg:col-start-1 lg:col-end-13 h-full w-full text-[#DA2F8C]`}
            hasSound={true}
          />
        </Grid>

        <div className="hidden lg:block">
          <StaticImage
            src={`../../static/images/about/pattern.jpg`}
            alt={``}
          />
        </div>
        <div className="block lg:hidden">
          <StaticImage
            src={`../../static/images/about/pattern_mobile.jpg`}
            alt={``}
          />
        </div>

        <StaticImage
          src={`../../static/images/about/image.jpg`}
          alt={``}
        />

        <ImageSlider
          arrowColor="white"
          arrowBgColor="black"
          images={firstSlides}
          heading="A brave new world.<br/> Optimistic, gender&#8209;free,<br/> designed for New Utopians"
        />

        <Grid className={`${overallPadding} mt-10 lg:mt-36`}>
          <div className={`col-start-1 col-end-13 lg:col-start-1 lg:col-end-6 mb-16 lg:mb-0`}>
            <StaticImage
              src={`../../static/images/about/about1.jpg`}
              alt={``}
            />
          </div>
          <div className={`col-start-2 col-end-11 lg:col-start-6 lg:col-end-13 mb-5 lg:mb-0`}>
            <div className={`lg:flex lg:flex-col lg:justify-center lg:h-full`}>
              <div className={`mx-auto w-full lg:w-8/12 ${overallPadding}`}>
                <p className={`${constants.h2}`}>
                  We are leather innovators
                </p><br/>
                <p className={`${constants.paragraph}`}>
                  Most accessory brands buy their leather. We craft and create our own for the needs of each unique bag. We push the boundaries of leather, with avante-garde colors and ultra-soft techniques
to water-repellent leathers. Our innovation team works alongside our Atelier designers, creating on-site in our Gold-Star rated tannery in Dongen,
The Netherlands.
                </p>
              </div>
            </div>
          </div>
        </Grid>
        <Grid className={`${overallPadding} mt-10 lg:mt-36`}>
          <div className={`col-start-2 col-end-11 lg:col-start-1 lg:col-end-8 mb-5 lg:mb-0`}>
            <div className={`lg:flex lg:flex-col lg:justify-center lg:h-full`}>
              <div className={`mx-auto w-full lg:w-8/12 ${overallPadding}`}>
                <p className={`${constants.h2}`}>
                Obsessed with design and craft
                </p><br/>
                <p className={`${constants.paragraph}`}>
                Our Atelier, on-site in Dongen, lets our creative team explore possibilities for never-seen-before details and designs. It's the place where we invent hype-worthy small runs, limited editions and collaborations.
                </p>
              </div>
            </div>
          </div>
          <div className={`col-start-2 col-end-12 lg:col-start-8 lg:col-end-13 mb-16 lg:mb-0`}>
            <StaticImage
              src={`../../static/images/about/about2.jpg`}
              alt={``}
            />
          </div>
        </Grid>
        <Grid className={`${overallPadding} mt-0 lg:mt-36`}>
          <InlineVideo
            src="Ecco-Leather.mp4"
            basepath={ss23_videoBasepath}
            className={`col-start-1 col-end-13 lg:col-start-1 lg:col-end-13 h-full w-full text-white`}
            hasSound={true}
          />
        </Grid>
        <Grid className={`${overallPadding} mt-10 lg:mt-14`}>
          <div className={`col-start-2 col-end-11 lg:col-start-1 lg:col-end-7 mb-5 lg:mb-0`}>
            <div className={`lg:flex lg:flex-col lg:justify-top lg:h-full`}>
              <div className={`w-11/12 lg:w-10/12`}>
                <p className={`${constants.h2}`}>
                  We are <br/>change-makers
                </p>
              </div>
            </div>
          </div>
          <div className={`col-start-2 col-end-13 lg:col-start-9 lg:col-end-13 mb-12 lg:mb-0`}>
            <div className={`lg:flex lg:flex-col lg:justify-top lg:h-full`}>
              <div className={`lg:mx-auto w-11/12 lg:w-full ${overallPadding}`}>
                <p className={`${constants.paragraph}`}>
                We believe in leather as a natural and high-quality by-product of the food we consume - and take our responsibility for sustainable production seriously. We are not a fully sustainable or circular brand yet, but are proud of the industry-leading innovations we are pioneering on-site at our Gold-Star rated tannery in The Netherlands.
                <br/><br/>
                The AW/21 Collection features DriTan  leather, which is made with zero wastewater or solid waste – revolutionizing the tanning
industry, for the better.
                </p>
              </div>
            </div>
          </div>
        </Grid>
        <Grid className={`${overallPadding} mt-4 lg:mt-28 pb-10 lg:pb-12`}>
          <div className={`lg:hidden col-start-1 col-end-13 lg:col-start-6 lg:col-end-13 mb-12 lg:mb-0`}>
            <StaticImage
              src={`../../static/images/about/about3.jpg`}
              alt={``}
            />
          </div>
          <div className={`col-start-2 col-end-13 lg:col-start-1 lg:col-end-6 mb-5 lg:mb-0`}>
            <div className={`lg:flex lg:flex-col lg:justify-center lg:h-full`}>
              <div className={`lg:mx-auto w-11/12 lg:w-10/12 ${overallPadding}`}>
                <p className={`${constants.h2}`}>
                  Living our heritage
                </p><br/>
                <p className={`${constants.paragraph}`}>
                  Our Scandinavian design ethos is simple and beautiful design, made to last rather than be replaced. For the good of people and planet we consistently push the aesthetic, technological and functional properties our our materials - especially in leather innovation. We are energized by archival visual elements from our brand’s beginning in the ‘60s and have drawn inspiration for our new brand mark pattern and E Collection Emblem.
                </p>
              </div>
            </div>
          </div>
          <div className={`hidden lg:block col-start-1 col-end-13 lg:col-start-6 lg:col-end-13 mb-16 lg:mb-0`}>
            <StaticImage
              src={`../../static/images/about/about3.jpg`}
              alt={``}
            />
          </div>
        </Grid>
      </div>
    </Layout>
  )
}

export default AboutPage
